import React from 'react';
import { render } from 'src/lib/utils';
import {
  createSubscriptionStore,
  SubscriptionStore,
} from 'src/features/subscription/subscription-form/use-subscription-form-store';
import { useDefaultPlan } from 'src/features/subscription/subscription-form/use-default-plan';
import {
  subscriptionBillingInterval,
  subscriptionClient,
} from 'src/lib/services/api/subscription-api';
import { useStore } from 'zustand';
import { useMakeQuery } from 'src/api/queries';

const SubscriptionCouponStoreContext = React.createContext<ReturnType<
  typeof useMakeQuery<null | Awaited<ReturnType<typeof subscriptionClient.validatePromoCode>>>
> | null>(null);

export const SubscriptionCouponStoreContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { get } = useDefaultPlan();

  const defaultValues = get();

  const couponQuery = useMakeQuery(
    ['coupon', defaultValues?.coupon],
    async () => {
      if (!defaultValues?.coupon) {
        return null;
      }

      return await subscriptionClient.validatePromoCode({ promotion_code: defaultValues.coupon });
    },
    {
      suspense: true,
      retry: false,
      staleTime: 60 * 60 * 1000,
    },
  );

  return (
    <SubscriptionCouponStoreContext.Provider value={couponQuery}>
      {children}
    </SubscriptionCouponStoreContext.Provider>
  );
};

const useSubscriptionCouponStoreContext = () => {
  const context = React.useContext(SubscriptionCouponStoreContext);

  if (!context) {
    throw new Error(
      'useSubscriptionCouponStoreContext must be used within a SubscriptionCouponStoreContextProvider',
    );
  }

  return context;
};

type SubscriptionFormStore = ReturnType<typeof createSubscriptionStore>;

const SubscriptionFormStoreContext = React.createContext<SubscriptionFormStore | null>(null);

const SubscriptionFormStoreContextProvider = ({ children }: { children: React.ReactNode }) => {
  const couponQuery = useSubscriptionCouponStoreContext();
  const { get } = useDefaultPlan();

  const defaultValues = get();

  // Initialize subscription form store with pre-selected plan and interval, if it is set.
  // Unable to delete the default values from storage initially, because multiple tabs can interact with it.
  const store = React.useRef(
    createSubscriptionStore({
      plan: defaultValues?.plan,
      billing_interval: defaultValues?.interval || subscriptionBillingInterval.quarterly,
      coupon: defaultValues?.coupon ?? '',
      promotion_code: couponQuery.data ? couponQuery.data : undefined,
    }),
  ).current;

  return (
    <SubscriptionFormStoreContext.Provider value={store}>
      {render(children)}
    </SubscriptionFormStoreContext.Provider>
  );
};

const useSubscriptionFormStoreContext = () => {
  const context = React.useContext(SubscriptionFormStoreContext);

  if (!context) {
    throw new Error(
      'useSubscriptionFormStoreContext must be used within a SubscriptionFormStoreContext',
    );
  }

  return context;
};

export const useSubscriptionFormStore = (): SubscriptionStore => {
  return useStore(useSubscriptionFormStoreContext());
};

const withCouponContext = <ComponentProps extends Record<string, any>>(
  Comp: React.FC<ComponentProps>,
) => {
  const Component: React.FC<ComponentProps> = (props: ComponentProps) => {
    return (
      <SubscriptionCouponStoreContextProvider>
        <Comp {...props} />
      </SubscriptionCouponStoreContextProvider>
    );
  };

  Component.displayName = Comp.displayName;

  return Component;
};

const SubscriptionFormStoreContextProviderWithCoupon = withCouponContext(
  SubscriptionFormStoreContextProvider,
);

export {
  SubscriptionFormStoreContext,
  SubscriptionFormStoreContextProviderWithCoupon as SubscriptionFormStoreContextProvider,
  useSubscriptionFormStoreContext,
};
